import React, { useEffect, useMemo } from 'react';
import {
  Route, Switch, Redirect, useLocation,
} from 'react-router-dom';

import { getCookie, setCookie } from 'helpers/cookies';
import { setVisits, setClientDecision } from 'services/user.services';

import PrivateRoute from 'containers/PrivateRoute';
import HomeContainer from 'containers/HomeContainer';
import ProductsContainer from 'containers/ProductsContainer';
import OffersContainer from 'containers/OffersContainer';
import MostSelledContainer from 'containers/MostSelledContainer';
import ProductSingleContainer from 'containers/ProductSingleContainer';
import SearchProductContainer from 'containers/SearchProductContainer';
import ContactContainer from 'containers/ContactContainer';
import CartContainer from 'containers/CartContainer';
import DeliveryContainer from 'containers/DeliveryContainer';
import PickupContainer from 'containers/PickupContainer';
import ProfileContainer from 'containers/ProfileContainer';
import PurchaseHistoryContainer from 'containers/PurchaseHistoryContainer';
import CatalogueContainer from 'containers/CatalogueContainer';
import FrequentQuestions from 'views/FrequentQuestions';
import SuccessfulPurchase from 'views/SuccessfulPurchase';
import Error from 'views/Error';
import PricesContainer from './PricesContainer';
import VerifyEmailContainer from 'containers/VerifyEmailContainer';
import ResetPassEmailContainer from 'containers/ResetPassEmailContainer';

const useQuery = () => {
  const { search } = useLocation();
  return useMemo(() => new URLSearchParams(search), [search]);
};

const NavigationContainer = () => {
  const query = useQuery();

  useEffect(() => {
    const updateCookies = async () => {
      try {
        let date = new Date();
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();
        date = `${year}-${month}-${day}`;
        const platform = query.get('platform') || 0;
        const key = `_plat_${platform}`;
        if (getCookie(key) !== date) {
          setCookie(key, date, { maxAge: 86400 });
          const data = { date, platform, isMobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) };
          await setVisits(data);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    };

    const clientDecision = async () => {
      try {
        const response = await setClientDecision();
        setCookie('visits_purcharse', response.visit.id, { maxAge: 86400 });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    };

    updateCookies();
    clientDecision();
    // eslint-disable-next-line
  }, []);

  return (
    <Switch>
      <Redirect exact from="/" to="/home" />
      <Route path="/home/:section?" component={HomeContainer} />
      <Route path="/productos/:category?" component={ProductsContainer} />
      <Route path="/ofertas/:category?" component={OffersContainer} />
      <Route path="/hot-sale/:category?" component={OffersContainer} />
      <Route path="/masVendido/:category?" component={MostSelledContainer} />
      <Route path="/producto/:product(.*)" component={ProductSingleContainer} />
      <Route path="/busqueda/:search/:category?" component={SearchProductContainer} />
      <Route path="/contacto" component={ContactContainer} />
      <Route path="/carrito" component={CartContainer} />
      <Route path="/envio-a-domicilio" component={DeliveryContainer} />
      <Route path="/retiro-en-sucursal" component={PickupContainer} />
      <Route path="/preguntas-frecuentes" component={FrequentQuestions} />
      <Route path="/catalogos" component={CatalogueContainer} />
      <Route path="/precios" component={PricesContainer} />
      <Route path="/compra-exitosa" component={SuccessfulPurchase} />
      <Route path="/verify-email" component={VerifyEmailContainer} />
      <Route path="/verify-password" component={ResetPassEmailContainer} />
      <PrivateRoute path="/perfil" component={ProfileContainer} />
      <PrivateRoute path="/compras" component={PurchaseHistoryContainer} />
      <Route component={Error} />
    </Switch>
  );
};

export default NavigationContainer;
